<template>
  <div>
    <a-row type="flex" justify="center" style="margin-top: -200px">
      <a-col :span="23" :lg="16">
        <a-row
          style="
            background-color: #ffffff;
            box-shadow: 0px 0px 17px -8px rgba(14, 155, 210, 20);
            border-radius: 8px;
          "
          :style="{ padding: fullWidth > 992 ? '20px 30px' : '20px 10px' }"
        >
          <a-col :span="24">
            <template>
              <el-page-header
                style="
                  width: 100%;
                  height: 50px;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  margin-left: 10px;
                "
                @back="backLegalList"
                content="获奖名单"
              >
              </el-page-header>
              <!-- 主体内容 -->
              <a-col :span="24" style="margin-bottom: 20px">
                <div v-html="htmlFormat(htmlData)"></div>
              </a-col>
            </template>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  created() {
    this.htmlData = JSON.parse(localStorage.getItem("awardHtml"));
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapState(["fullWidth"]),
  },
  data() {
    return {
      loading: true,
      empty: false,
      awardList: [],
      htmlData: "",
    };
  },
  methods: {
    backLegalList() {
      this.$router.back();
    },

    // 获取获奖名单信息
    getAwardList() {
      this.$HTTP
        .httpToken({
          url: `/wst/testPaper/topicInfo/${this.$route.query.topicId}`,
          method: "post",
          data: {},
        })
        .then((res) => {
          console.log("获奖名单", res);
          this.loading = false;
          if (res.code == "0" && res.data && res.data.length > 0) {
            this.empty = false;
            this.awardList = res.data;
          } else {
            if (this.pageIndex == 1) {
              this.empty = true;
              this.description = "暂无数据";
            } else {
              this.empty = false;
            }
          }
        })
        .catch((e) => {
          this.loading = false;
          if (this.pageIndex == 1) {
            this.empty = true;
            this.description = e;
          } else {
            this.empty = false;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
